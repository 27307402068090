.page-banner {
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
}

.page-banner__triangle {
  width: 0;
  height: 0;
  border-top: 150px solid transparent;
  border-left: 600px solid rgba(0, 0, 0, 0.5);
  border-bottom: 0 solid transparent;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: $sm-max) {
    display: none;
  }
}

.page-banner__wrapper {
  min-height: 150px;
  display: flex;
  align-items: flex-end;
}

.page-banner__title {
  color: $white;
  margin-left: 28px;
  position: relative;
}
