.product-card {
  width: calc(50% - 2em);
  display: flex;
  flex: 0 0 calc(50% - 2em);
  box-shadow: 0 0 12px -1px rgba(0, 0, 0, 0.75);
  border-radius: 0.28571429rem;
  margin: 0.875em 1em;

  @media (max-width: 680px) {
    width: calc(100% - 2em);
    flex: 1 1 calc(100% - 2em);
  }
}

.product-card__wrapper {
  padding: 16px 16px 5px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  // height: 100%;
  width: 100%;
  flex: 0 1 100%;
}

.product-card__header {
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 10px;
  align-items: flex-start;

  @media (max-width: $small-monitor-max) {
    display: block;
  }
}

.product-card__header-title {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 5px;
  line-height: 19px;
}

.product-card__header-info {
  flex: 0 1 100%;
  width: 100%;
}

.product-card__header-info-inner-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.product-card__info-field {
  font-size: 12px;
  width: 50%;
}

.product-card__price {
  display: flex;
  width: 100%;
}

.product-card__svg {
  display: block;
  padding-right: 15px;

  svg,
  img {
    margin: 15px 0;
    width: 60px;
    height: 60px;

    @media (max-width: $small-monitor-max) {
      display: block;
      margin: 0 auto;
    }
  }

  span {
    display: block;
  }
}

.product-card__button {
  @include button($light-text-grey, $button-grey);
}

.product-card__button--add-to-cart {
  @include button($light-text-grey, $button-grey);

  i {
    color: $rmr-blue !important;
  }
}

.product-card__controls {
  display: flex;
  flex-flow: row wrap;
}

.product-card__control-col {
  margin: 0 3px !important;
  width: calc(50% - 6px);
  flex: 0 0 calc(50% - 6px);

  .product-card__button {
    width: 100%;
  }
}
