// stylelint-disable no-descending-specificity
.callout-wrapper {
  background-color: $cyan;
  padding-top: 50px;
  margin-bottom: 75px;
  text-align: center;
  color: $white;

  .ui.grid {
    padding: 50px 0 30px 0;

    a {
      color: $white;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: $tablet-max) {
  .callout-button-container {
    margin-bottom: 30px !important;
  }
}

.callout-button {
  background: rgba(255, 255, 255, 0.2);
  padding: 3em;
  border-radius: 12px;
  padding-bottom: 1em;
  width: 100%;
  height: 100%;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
}

.category-list {
  text-align: center;
  margin-bottom: 30px;

  .row {
    .column {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  h2 {
    display: block;
    margin: 0 auto;
    color: $cyan;
  }

  a {
    color: $mine-shaft;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    @media (min-width: 768px) and (max-width: $row-reverse-small) {
      font-size: 12px;
    }
  }
}

.article-list {
  background: #eef0f1;
  padding-top: 50px;
  word-break: break-word;

  .row {
    .column {
      padding-bottom: 20px;
    }
  }

  h2 {
    display: block;
    color: $mine-shaft;
    text-transform: uppercase;
  }

  a {
    color: $mine-shaft;
    text-decoration: none;
    line-height: 20px;
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }

    img {
      margin-bottom: 10px;
    }
  }

  .four.column {
    padding-bottom: 50px;
  }

  .article-list__header {
    font-size: 16px;
    margin-top: 0;
    font-weight: 600;
  }
}
