.breadcrumbs {
  color: $pale-sky;
  font-size: 14px;
  margin-bottom: 10px;

  a {
    // color: inherit;
    font-weight: 600;
    text-decoration: none;
  }
}

.breadcrumbs--tight {
  margin-bottom: -10px;
}

.page-content--no-horizontal-padding {
  .breadcrumbs {
    @media (max-width: $sm-max) {
      padding: 20px 20px 0;
    }
  }
}

.breadcrumb {
  text-align: left;
}

.breadcrumb__back-link {
  color: $pale-sky;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 250px;
  font-size: 14px;
  font-weight: 400;

  svg {
    height: 17px;
    margin-bottom: 3px;
  }

  span {
    margin-left: 5px;
  }

  &:hover {
    color: $pale-sky;
    text-decoration: none;
  }
}

.breadcrumb__current-title--lower-case {
  @extend .breadcrumb__current-title;

  text-transform: initial !important;
}

.breadcrumb__current-title {
  text-align: inherit;
  color: $black;
  text-transform: capitalize;
}
