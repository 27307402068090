.product__title {
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}

.product__add-to-cart {
  @include button($white, $rmr-blue);

  padding: 10px !important;

  i {
    color: $white !important;
    opacity: 1 !important;
  }
}

.product__price {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  color: $rmr-blue;
  margin-right: 5px;
  margin-bottom: 12px;

  @media (max-width: $small-monitor-max) {
    margin-bottom: 0;
  }
}

.product__extra-info {
  span {
    font-size: 12px;
    font-weight: 700;
    margin-right: 10px;
  }
}

.product__sidebar-column {
  padding: 0 !important;
}

.product-content {
  margin-bottom: 15px !important;
}
