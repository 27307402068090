.ui.button {
  &.button--hollow {
    background-color: transparent;
    border: 1px solid $white;
    color: $white;

    @include transition(background-color, color, transform);

    &:hover,
    &:focus {
      background-color: white;
      color: inherit;
    }
  }

  &.inverted {
    border: 1px solid $mischka;

    &:hover,
    &:focus {
      border: 1px solid transparent;
    }
  }

  &[class*='button--left-icon'].button--left-icon-download {
    background-size: 14px;
  }

  &.button--wide {
    @media (min-width: $sm-min) {
      width: 100%;
    }
  }
}

.button-group {
  .ui.button {
    + .ui.button {
      margin-left: 20px;
    }
  }
}
