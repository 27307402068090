.contact-page {
  padding: 20px 0;

  .contact-page__block {
    text-align: center;

    .invalid {
      color: #eb1c26;
    }

    .valid {
      color: #008000;
    }
  }

  .ui.form {
    input {
      height: 43px !important;
    }
  }
}
