@mixin message-styling {
  background-color: white;
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 30px;
  padding-left: 65px;
  box-shadow: none;
  border: 1px solid $mischka;
  color: $black;
  border-radius: 0;
  margin: 15px 0 15px !important;
  font-weight: 600;
}

.ui.message {
  @include message-styling;

  background-image: url(../images/icons8-info.svg);

  &.error,
  &.warning,
  &.success {
    @include message-styling;
  }

  &.error {
    background-image: url(../images/icons8-cancel.svg);
    color: #a2413e;
    background-size: 30px 30px;
  }

  &.warning {
    background-image: url(../images/icons8-attention.svg);
    color: #d89118;
    background-size: 30px 30px;
  }

  &.success {
    background-image: url(../images/icons8-ok.svg);
    color: $cyan;
    background-size: 30px 30px;
  }

  > .close.icon {
    top: 15px;
    right: 15px;
    height: 25px;

    &::before {
      color: $heather;
    }
  }
}

.message-transition-appear {
  opacity: 0;
}

.message-transition-appear-active {
  opacity: 1;
  transition: opacity $transition-time * 2 !important;
}
