body {
  background-color: $white;
  margin-top: 65px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
  letter-spacing: 0;
}

h1 {
  font-size: 36px;
  font-weight: 300;
  color: inherit;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  font-weight: 400;
}

h3 {
  font-size: 22px;
  font-weight: 300;
}

p {
  font-family: $proxima-nova;
}

.callout {
  padding: 30px;
  text-align: left;

  @media (max-width: $sm-max) {
    border: none;
    padding: 30px 20px;
  }
}

.stat-title {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: $pale-sky;
  margin-bottom: 20px;
}

.svg-container {
  text-align: center;

  svg {
    width: 100%;
  }
}

.info-tooltip {
  @include info-tooltip;
}

img {
  max-width: 100%;
}

.caption {
  @include caption;
}

.ui.grid.page-container {
  margin-top: 0;
  margin-bottom: 0;
}

.bold {
  font-weight: 600;
}

.noPaddingOrMargin {
  margin: 0 !important;
  padding: 0 !important;
}
