.light-title {
  font-weight: 300;
  text-align: left;
  height: 20px;
  font-size: 24px;
}

.already-in-cart {
  color: #03c063;
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 15px;
}
