.page-container {
  min-height: 50vh;

  .thank-you-content {
    padding-bottom: 50px;

    h2 {
      text-align: center;
      margin-bottom: 35px;
    }

    p {
      text-align: center;
    }
  }
}
