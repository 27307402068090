@mixin caption {
  font-size: 14px;
  font-weight: 200;
  font-style: italic;
  color: $pale-sky;
  line-height: 21px;
}

@mixin info-tooltip {
  @include caption;

  cursor: pointer;
  padding-left: 30px;
  background-image: url(../images/icons/icons8-info_filled.svg);
  background-size: contain;
  line-height: 16px;
  background-position: left center;
  background-repeat: no-repeat;
}
