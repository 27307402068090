// stylelint-disable no-descending-specificity
.styled-datatable.orders-listing {
  .ui.table {
    thead {
      tr {
        th:last-child {
          display: flex;
          justify-content: flex-end;
        }

        @media (max-width: $sm-max) {
          padding: 5px 0;

          th {
            padding-top: 2px !important;
            padding-bottom: 2px !important;
          }
        }
      }
    }

    tbody {
      tr {
        &.grey {
          background: #eee;
        }

        &:hover {
          background: #d8d8d8 !important;
        }

        td {
          padding: 0 !important;

          a {
            padding: 15px;
            display: flex;
            text-decoration: none;
            color: $header-grey;
            font-weight: 400;

            @media (max-width: $sm-max) {
              padding: 5px 15px;
            }
          }

          &:last-child {
            display: flex;
            justify-content: flex-end;
          }
        }

        @media (max-width: $sm-max) {
          padding: 10px 0;
        }
      }

      @media (max-width: $sm-max) {
        max-height: 355px;
        overflow-y: scroll;
      }
    }

    @media (max-width: $sm-max) {
      margin-top: 0;
    }
  }

  @media (max-width: $sm-max) {
    h2 {
      margin: 0 5px;
    }
  }
}

.order__item-listing {
  h2 {
    margin-bottom: 20px;
  }

  .ui.grid {
    .row {
      padding-left: 5px;
      display: flex;
      align-items: center;
      background-color: rgba(245, 246, 248, 0.7);

      &.grey {
        background: #eee !important;
        color: $header-grey;

        @media (max-width: $sm-max) {
          border-top: 0.5px solid rgba(0, 0, 0, 0.1) !important;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.1) !important;
        }
      }

      &:hover {
        background: #d8d8d8 !important;
      }

      .column {
        padding-left: 3px;
        padding-right: 3px;
        word-break: break-word;
        text-align: center;

        &.text-left {
          text-align: left;
        }

        @media (max-width: $sm-max) {
          text-align: left;
          padding: 3px 25px;
        }
      }

      .item-name {
        padding-left: 15px;

        @media (max-width: $sm-max) {
          padding: 3px 25px;
        }
      }
    }

    .order__item-listing-header {
      border: none;
      border-bottom: 1px solid #bbc4d1;
      background: transparent;
      color: #0f849e;
      padding: 20px 0 0 5px;

      .column {
        font-weight: 700;
      }

      &:hover {
        background-color: transparent !important;
      }

      @media (max-width: $sm-max) {
        border-bottom: none;
      }
    }

    .order__item-listing-body {
      margin-top: 0;

      @media (max-width: $sm-max) {
        padding: 0;
        margin: 0 15px;
        max-height: 355px;
        overflow-y: scroll;
      }

      .row {
        @media (max-width: $sm-max) {
          padding: 10px 0;
        }

        .column {
          @media (max-width: $sm-max) {
            padding: 5px 15px;
          }
        }
      }
    }
  }
}

.order__totals {
  margin-bottom: 30px;

  > .column:nth-of-type(2) {
    background: #eee;
    padding: 20px 20px 40px 20px;

    .row {
      .five.wide.column {
        text-align: right;
      }
    }
  }

  .row {
    padding: 14px 0 0 0 !important;
  }
}

.order__download-url {
  text-align: center;
  font-size: 10px;

  i {
    width: 100%;
    font-size: 20px;

    @media (max-width: $sm-max) {
      text-align: left;

      &::before {
        margin-left: 8px;
      }
    }
  }
}
