.register-page {
  .ui.grid {
    margin-top: 0;
  }

  .single-form.register {
    background-color: $white;
    border: 1px solid #d0d5dc;
    padding: 30px;
    margin-bottom: 30px;

    form.ui.form {
      margin-top: 20px;

      .organisation-form__submit {
        float: none;
        margin-right: 15px;
      }
    }
  }
}
