#related-product__wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
}

.related-products__header {
  margin-bottom: 30px;
}

.related-product {
  box-shadow: 0 0 12px -1px rgba(0, 0, 0, 0.75);
  background: $white;
  margin-bottom: 40px;
  max-width: 100%;
}

.related-product__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: url('/coast.png');
  background-size: cover;
  min-height: 73px;

  .isvg {
    svg {
      width: 35px;
      height: 35px;
      margin: 10px;
      vertical-align: middle;
    }
  }

  img {
    width: 35px;
    height: 35px;
    margin: 10px;
    vertical-align: middle;
  }

  h3 {
    margin: 0 !important;
    padding: 0;
    color: $white !important;

    @media (max-width: $small-monitor-max) {
      text-align: center !important;
      padding-bottom: 10px !important;
    }

    @media (max-width: $tablet-max) {
      padding-bottom: 0 !important;
    }
  }

  @media (max-width: $small-monitor-max) {
    flex-flow: column nowrap;
    text-align: center !important;
  }

  @media (max-width: $tablet-max) {
    flex-flow: row nowrap;
  }
}

.related-product__body {
  padding: 20px 15px 25px;
}

.related-product__controls {
  padding: 25px 15px 0;

  @media (max-width: $small-monitor-max) {
    padding: 25px 0 0;
  }

  @media (max-width: $tablet-max) {
    padding: 25px 15px 0;
  }
}

.related-product__button {
  @include button($light-text-grey, $button-grey);

  margin: 10px auto !important;
  font-size: 16px !important;

  &:first-of-type {
    margin-top: 0 !important;
  }

  &:last-of-type {
    margin-bottom: 0 !important;
  }
}

.related-product__button--add-to-cart {
  @include button($light-text-grey, $button-grey);

  i {
    color: $rmr-blue !important;
  }

  font-size: 16px !important;
}

.related-product__already-in-cart {
  padding-top: 10px;
}
