.products-listing {
  padding: 15px 0;
}

.product-listing__pagination-wrapper {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}

.product-listing__pagination-wrapper--top {
  margin-top: 0;
}

@media (max-width: 570px) {
  .product-listing__pagination-wrapper {
    .pagination {
      a.item {
        padding-left: 12px !important;
        padding-right: 12px !important;
        min-width: 2em !important;
        // &:first-of-type,
        // &:last-of-type,
        &:nth-of-type(2),
        &:nth-last-of-type(2),
        &:nth-last-of-type(3),
        &.disabled,
        &:nth-of-type(4) {
          display: none;
        }

        .active {
          display: block;
        }
      }
    }
  }
}

.no-results-content {
  padding-bottom: 50px;

  p {
    text-align: center;
  }
}
