.text--right {
  text-align: right;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-top-30 {
  margin-top: 30px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-top-10 {
  padding-top: 10px;
}

.row--reversed {
  flex-direction: row-reverse !important;
}

.row--reversed--small {
  @media (max-width: $row-reverse-small) {
    flex-direction: column-reverse !important;
  }
}
