.faq-page {
  h2 {
    padding-left: 10px;
  }

  h3 {
    padding-left: 20px;
  }

  p,
  ol {
    padding-left: 30px;
  }
}
